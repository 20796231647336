import React from "react";
import { useNavigate } from "react-router-dom";

function Title() {
  const navigate = useNavigate();

  let menu = ["home", "about", "skills", "projects", "contact"];

  return (
    <nav className="flex justify-center items-center py-5 ">
      <ul className="flex md:space-x-6 space-x-2 overflow-auto">
        {menu.map((x) => (
          <li
            key={x}
            className="hover:text-red-500 hover:cursor-pointer"
            onClick={() => navigate(`/${x}`)}
          >
            {x.toUpperCase()}
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Title;
